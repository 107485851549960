// Core

@import 'core';

// Fonts

@import url('https://fonts.googleapis.com/css?family=Heebo:400,700&display=swap');

// Widgets

@import 'widgets';

// Components

@import 'components';

// Layout Components

@import 'layout-components';

// Layout Blueprints

@import 'layout-blueprints';

.fade-enter {
    opacity: 0.01;
}

.fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 500ms ease-in;
}

.fade-exit {
    opacity: 1;
}

.fade-exit.fade-exit-active {
    opacity: 0.01;
    transition: opacity 300ms ease-in;
}

.context-menu-button {
    position: relative;
}

.context-menu-button.active button {
    background-color: #3c44b1;
    border-color: #3c44b1;
    color: white;
    white-space: nowrap;
}

.context-menu-button.active button:after {
    border-bottom-color: white;
}

.context-menu-button button {
    width: 100%;
    background-color: #fff;
    border: 1px solid #e3e7ea;
    border-radius: 2px;
    padding: 12px;
    font-size: 14px;
    color: #81858e;
    font-weight: 500;
    outline: none;
    cursor: pointer;
    position: relative;
    padding-right: 30px;
    font-weight: 600;
    text-align: left;
    white-space: nowrap;
}

.context-menu-button button:after {
    top: 50%;
    right: 8px;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    margin-top: -3px;
    transform: rotate(180deg);
}

.context-menu-button button:after {
    border-color: transparent;
    border-bottom-color: #81858e;
    border-width: 6px;
}

.context-menu-button .context-menu {
    position: absolute;
    top: 100%;
    margin-top: 15px;
    opacity: 0;
    visibility: hidden;
    transition: visibility 0s, opacity 0.2s ease-in-out;
}

.context-menu-button.small .context-menu {
    min-width: auto;
}

.context-menu-button .context-menu,
.context-menu-button.medium .context-menu {
    min-width: 300px;
}

.context-menu-button.open .context-menu {
    visibility: visible;
    opacity: 1;
    z-index: 99999;
}

.context-menu {
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border: 1px solid #81858e;
    box-shadow: 2px 2px 9px rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    font-size: 15px;
}

.context-menu:before,
.context-menu:after {
    bottom: 100%;
    left: 20px;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
}

.context-menu:after {
    border-color: transparent;
    border-bottom-color: #fff;
    border-width: 8px;
    margin-left: -8px;
}

.context-menu:before {
    border-color: transparent;
    border-bottom-color: #81858e;
    border-width: 9px;
    margin-left: -9px;
}

.context-menu,
.context-menu.small {
    width: 220px;
}

.context-menu.medium {
    width: 300px;
}

.context-menu * {
    box-sizing: border-box;
}

.context-menu .context-menu-option {
    padding: 10px 16px;
}

.context-menu .context-menu-option:hover {
    cursor: pointer;
    background-color: #f9f9f9;
}

.context-menu label.context-menu-option {
    display: flex;
    align-items: center;
    cursor: pointer;
    color: #9699a0;
    font-weight: 600;
}

.context-menu label.context-menu-option input {
    margin-right: 10px;
}

.context-menu label.context-menu-option .count {
    margin-left: auto;
    font-weight: 500;
    font-size: 13px;
}

.context-menu .context-menu-header {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    display: flex;
    align-items: center;
    padding: 22px 16px 10px 16px;
    width: 100%;
    color: #898989;
    background-color: #fff;
}

.context-menu .context-menu-header input[type='search'] {
    -webkit-appearance: searchfield;
}

.context-menu .context-menu-header input[type='search']::-webkit-search-cancel-button {
    -webkit-appearance: searchfield-cancel-button;
}

.context-menu .context-menu-header input[type='search'],
.context-menu .context-menu-header input[type='text'] {
    width: 100%;
    height: 30px;
    padding: 8px;
    font-size: 14px;
}

.context-menu .context-menu-header ~ .context-menu-section {
    margin-top: 62px;
    max-height: 260px;
    padding-bottom: 12px;
    overflow-y: auto;
}

.context-menu .context-menu-separator {
    height: 1px;
    margin: 0px 0px;
    border-top: 1px solid #81858e;
}

.context-menu .context-menu-footer {
    display: flex;
    justify-content: space-between;
    background-color: #f8f9f9;
    padding: 20px 16px;
    margin-top: 12px;
}

.context-menu .context-menu-footer a {
    display: inline-flex;
    width: 100%;
    text-align: center;
    justify-content: center;
    font-weight: bold;
    cursor: pointer;
}

.context-menu .context-menu-footer a.primary {
    color: #1f83fb;
}

.right-to-left-position > button {
    padding: 6px;
    padding-right: 26px;
}

.right-to-left-position .context-menu {
    left: -210px;
}

.filters {
    padding: 8px;
}

.filters ul {
    display: flex;
    justify-content: space-around;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
}

.filters ul li {
    display: block;
}

.filters ul .filter {
    position: relative;
}

.filters ul .filter label {
    display: inline-block;
    cursor: pointer;
    border-radius: 4px;
    padding: 6px 20px;
    font-size: 13px;
}

.filters ul .filter label:hover {
    background-color: #fff;
}

.filters ul .filter input[type='radio'] {
    position: absolute;
    opacity: 0;
    visibility: hidden;
}

.filters ul .filter input[type='radio']:checked + label {
    background-color: #fff;
    color: #1180ff;
}

.filter-bar {
    display: flex;
    align-items: center;
}

.filter-bar .filter {
    // margin-bottom: 20px;
    margin-right: 20px;
}

@media (max-width: 991px) {
    .filter-bar .filter {
        margin-right: 0;
    }
}

.filter-bar .filter:first-child {
    margin-left: 0;
}

@media (max-width: 991px) {
    .filter-bar {
        flex-wrap: wrap;
    }

    .filter-bar .filter {
        width: 100%;
        margin-left: 0;
        margin-bottom: 10px;
    }

    .filter-bar .filter .context-menu {
        min-width: 100%;
    }

    .filter-bar #date-range-picker-wrapper {
        width: 100%;
        margin-left: 0;
        margin-bottom: 10px;
    }

    .filter-bar #date-range-picker-wrapper .date-result {
        min-width: 100%;
    }

    .filter-bar .task-clear-filter {
        margin-left: -15px !important;
        height: 25px;
    }
}

.deal-draggable-stage-container {
    border: 1px solid #d3d3d3;
    border-radius: 2px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
}

.deal-draggable-stage-title {
    padding-left: 8px;
}

.deal-draggable-stage-taskList {
    padding: 4px 11px;
    transition: background-color 0.2s ease;
    flex-grow: 1;
    min-height: 50px;
}

.deal-draggable-step-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    background-color: #fafafa;
    border: 1px #e1e2e4 dashed;
    padding: 5px;
    border-radius: 6px;
    margin-bottom: 4px;
}

.deal-draggable-step-container-highlighted {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    background-color: rgba(17, 128, 255, 0.1);
}

.deal-draggable-step-drag-handle-hovered {
    margin-left: -40px;
    width: 30px;
    position: relative;
    top: -45px;
    height: 10px;
    z-index: 999;
    float: left;
}

.deal-draggable-step-drag-handle {
    margin-left: -40px;
    width: 30px;
    position: relative;
    top: -45px;
    height: 10px;
    z-index: 999;
    float: left;
}

.deal-draggable-step-drag-handle-visible {
    margin-right: 8px;
    height: 20px;
    width: 20px;
    border-radius: 10px;
    background-color: #008000;
    opacity: 1;
    transition-duration: 0.2s;
    transition-timing-function: ease-in-out;
}

.deal-draggable-step-drag-handle-hidden {
    margin-right: 8px;
    height: 20px;
    width: 20px;
    border-radius: 10px;
    opacity: 0;
    transition-duration: 0.2s;
    transition-timing-function: ease-in-out;
}

.deal-add-stage-container {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
}

.drag-new-step-card-container {
    display: flex;
    padding: 8px;
    margin-left: 45px;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    align-items: center;
}

// @media (max-width: $screen-mobile) {
//   .drag-new-step-card-container {
//     width: 70%;
//   }

//   .drag-new-step-card-container svg {
//     min-width: 20px;
//   }
// }

.drag-step-card-container {
    display: flex;

    margin-left: 20px;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}

.drag-step-card-container .completion-icon {
    margin-right: 18px;
    flex-shrink: 1;
}

.drag-step-card-container .title {
    flex-grow: 1;
    // color: $regular;
    font-weight: 600;
}

@media (max-width: $screen-mobile) {
    [data-rbd-droppable-context-id='0'] .left-aligned {
        max-width: 170px;
    }

    [data-rbd-droppable-context-id='0'] .stacked-text {
        max-width: 110px;
    }
}

.search-bar-input {
    // border: none !important;
    // border-bottom: 1px solid#E0E0E0!important;
    // border-radius: 0 !important;
    // box-shadow: none !important;
}

.stage-sidebar-content .stage-title {
    font-size: 19px;
    font-weight: bold;
}

.stage-sidebar-content .stage-description {
    color: #2d3343;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
}

.stage-sidebar-content .assign-wrapper {
    margin-top: 30px;
    margin-bottom: 0.67em;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.stage-sidebar-content .assign-wrapper > div {
    width: 50%;
}

.stage-sidebar-content .assign-wrapper > div:first-child {
    width: 45%;
    margin-right: 5%;
}

.stage-sidebar-content .assign-wrapper > div:first-child > div {
    display: flex;
}

.stage-sidebar-content .assign-wrapper__title {
    color: #81858e;
    font-size: 16px;
    font-weight: 700;
    margin: 0;
    margin-bottom: 15px;
}

@media (max-width: 500px) {
    .stage-sidebar-content .assign-wrapper > div {
        width: 100% !important;
        margin-bottom: 15px;
    }
}

.drag-stage-card-container {
    display: flex;
    // border: 1px dashed #d3d3d3;
    border-radius: 10px;
    background-color: #fff;
    flex-direction: column;
    margin-top: 14px;
}

.drag-stage-card-container .noncollapsible-stage-section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    min-height: 80px;
    padding: 10px 0px;
}

@media (max-width: $screen-mobile) {
    .drag-stage-card-container .noncollapsible-stage-section {
        min-height: 40px;
    }
}

.drag-stage-card-container .noncollapsible-stage-section .left-aligned {
}

.drag-stage-card-container .noncollapsible-stage-section .left-aligned .stage-drag-handle-visible {
    height: 20px;
    width: 20px;
    background-color: #008000;
    border-radius: 10px;
}

.drag-stage-card-container .noncollapsible-stage-section .left-aligned .stage-drag-handle-hidden {
    height: 20px;
    width: 20px;
}

.drag-stage-card-container .noncollapsible-stage-section .left-aligned .stage-drag-handle {
    height: 20px;
    width: 20px;
    margin-top: -5px;
    margin-right: 5px;
}

.drag-stage-card-container .noncollapsible-stage-section .left-aligned .completion-icon {
    height: 20px;
    width: 20px;
    border-radius: 10px;
}

.drag-stage-card-container .noncollapsible-stage-section .left-aligned .stacked-text {
    margin-right: 10px;
    width: 80%;
}

@media (max-width: $screen-mobile) {
    .drag-stage-card-container .noncollapsible-stage-section .left-aligned .stacked-text {
        margin-right: 0;
        width: 70%;
    }
}

.drag-stage-card-container .noncollapsible-stage-section .left-aligned .stacked-text .stage-title,
.drag-stage-card-container .noncollapsible-stage-section .left-aligned .stacked-text .new-stage-title {
    font-size: 19px;
    font-weight: 600;
    color: #2d3343;
    width: 100%;
    font-size: 19px;
    font-weight: 600;
    color: #2d3343;
}

@media (max-width: $screen-mobile) {
    .drag-stage-card-container .noncollapsible-stage-section .left-aligned .stacked-text .stage-title {
        width: 100%;
        max-width: 180px;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.drag-stage-card-container .noncollapsible-stage-section .left-aligned .stacked-text .step {
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    color: #747d86;
}

.drag-stage-card-container .noncollapsible-stage-section .right-aligned {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.drag-stage-card-container .noncollapsible-stage-section .right-aligned .add-task-button {
    border: 1px solid #979797;
    border-radius: 3px;
    margin-left: 10px;
    margin-right: 10px;
    padding: 3px 15px;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
}

.drag-stage-card-container .noncollapsible-stage-section .left-aligned .toggle-arrow-point-down {
    height: 20px;
    width: 20px;
    margin-right: 5px;
    transform: rotate(-90deg);
    transition-duration: 0.2s;
    transition-timing-function: ease-in-out;
    cursor: pointer;
}

@media (max-width: $screen-mobile) {
    .drag-stage-card-container .noncollapsible-stage-section .left-aligned .toggle-arrow-point-down {
        margin-right: 0;
    }
}

.drag-stage-card-container .noncollapsible-stage-section .left-aligned .toggle-arrow-point-up {
    height: 20px;
    width: 20px;
    margin-right: 5px;
    transition-duration: 0.2s;
    transition-timing-function: ease-in-out;
    // transform: scaleY(-1);

    cursor: pointer;
}

@media (max-width: $screen-mobile) {
    .drag-stage-card-container .noncollapsible-stage-section .right-aligned .toggle-arrow-point-up {
        margin-right: 0;
    }
}

.drag-stage-card-container .noncollapsible-stage-section .right-aligned .edit-add {
    height: 5px;
    min-width: 75px;
    margin-right: 20px;
    margin-left: 10px;
}

@media (max-width: $screen-mobile) {
    .drag-stage-card-container .noncollapsible-stage-section .right-aligned .button.edit-add {
        padding: 10px 3px;
    }

    .drag-stage-card-container .noncollapsible-stage-section .right-aligned .edit-add {
        min-width: 40px;
        margin-right: 10px;
        margin-left: 0;
    }
}

.drag-stage-card-container .collapsible-stage-section {
    display: flex;
    flex-direction: column;
    padding-left: 70px;
    padding-right: 65px;
    // transition-duration: .2s;
    // transition-timing-function: ease-in-out;
    // transition-property: height;
}

.drag-stage-card-container .collapsible-stage-section .stage-description {
    color: #2d3343;
    font-size: 18px;
    line-height: 25px;
    margin-top: -18px;
    margin-left: 65px;
}

.drag-stage-card-container .collapsible-stage-section-collapsed {
    display: none;
    // transition-property: height;
    // height: 0px;
    // transition-duration: .2s;
    // transition-timing-function: ease-in-out;
}

.text-input-autocomplete input {
    text-overflow: ellipsis;
    width: 100%;
    padding: 5px;
    padding-left: 10px;
    border-radius: 0.2rem;
    border-color: rgba(0, 0, 0, 0.23);
    border-style: solid;
    border-width: 1px;
}

.text-input-autocomplete input:not(:last-child) {
}

.text-input-autocomplete::before,
.text-input-autocomplete::after {
    // content: '';
    position: absolute;
    box-sizing: border-box;
}

.text-input-autocomplete:not(.summary) input {
    padding-right: 45px;
}

.text-input-autocomplete:not(.summary)::after {
    top: 13px;
    right: 18px;
    height: 13px;
    width: 13px;
    border-radius: 20px;
    border: 1px solid black;
}

.text-input-autocomplete:not(.summary)::before {
    bottom: 11px;
    right: 17px;
    width: 2px;
    height: 6px;
    transform: rotate(-45deg);
    background-color: black;
}

.autocomplete {
    position: absolute;
    margin-top: 5px;
    width: inherit;
    z-index: 200;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.23);
    border-width: 1px;
    border-radius: 5px;

    margin-bottom: 20px;
}

.autocomplete .autocomplete-item {
    background-color: #fff;
    padding: 10px;
    color: #555;
    cursor: pointer;
}

.autocomplete .autocomplete-item.autocomplete-item-active {
    background-color: rgba(210, 214, 255, 1);
}

.document-sidebar .task-status-sidebar {
    display: flex;
    font-size: 15px;
    height: 28px;
    justify-content: center;
    position: relative;
    right: 60px;
    color: #529fdf;
}

.document-sidebar .task-status-sidebar.task-completed {
    color: #3a6;
}

@media (max-width: $screen-mobile) {
    .document-sidebar .task-status-sidebar {
        position: inherit;
    }
}

.document-sidebar .task-sidebar-status {
    position: relative;
    padding-left: 8px;
    top: 3px;
}

.document-sidebar .task-sidebar-date {
    cursor: pointer;
}

.document-sidebar .task-quote-button {
    height: 10px;
}

.task-sidebar-content,
.folder-sidebar-content {
    padding: 20px;
}

.task-sidebar-content .task-name,
.folder-sidebar-content .task-name {
    font-size: 20px;
    font-weight: 700;
}

.task-sidebar-content div.form-group,
.folder-sidebar-content div.form-group {
    padding-top: 0;
}

.task-sidebar-content div.form-group div.inputs,
.folder-sidebar-content div.form-group div.inputs {
    width: 100% !important;
    max-width: 100% !important;
}

.task-sidebar-content .multi-select-search-placeholder,
.folder-sidebar-content .multi-select-search-placeholder {
    align-items: center;

    display: flex;
    font-size: 14px;
}

.task-sidebar-content .multi-select-search-placeholder svg,
.folder-sidebar-content .multi-select-search-placeholder svg {
    margin-right: 10px;
    margin-left: 5px;
}

.task-sidebar-content .multi-select-search-placeholder svg path,
.folder-sidebar-content .multi-select-search-placeholder svg path {
    fill: none;
}

.task-sidebar-content h4,
.folder-sidebar-content h4 {
    margin-bottom: 12px;
}

.task-sidebar-content .dialog-wrapper-container.sm,
.folder-sidebar-content .dialog-wrapper-container.sm {
    margin: 0;
    width: 100%;
    max-width: 100%;
    z-index: 0;
}

.task-sidebar-content .due-date-and-reminders,
.folder-sidebar-content .due-date-and-reminders {
    display: flex;
    justify-content: space-between;
}

.task-sidebar-content .due-date-and-reminders > div,
.folder-sidebar-content .due-date-and-reminders > div {
    margin: 3px;
}

.task-sidebar-content .due-date-and-reminders .with-tooltip-i,
.folder-sidebar-content .due-date-and-reminders .with-tooltip-i {
    margin-top: 1.33em;
}

.task-sidebar-content .due-date-and-reminders .with-tooltip-i h4,
.folder-sidebar-content .due-date-and-reminders .with-tooltip-i h4 {
    margin-top: 0px;
}

.task-sidebar-content .due-date-and-reminders input,
.folder-sidebar-content .due-date-and-reminders input,
.task-sidebar-content .due-date-and-reminders .select-field,
.folder-sidebar-content .due-date-and-reminders .select-field {
    font-size: 14px;
    width: 130px;
}

.SessionTimeoutWarning-root-1 {
    z-index: 2001 !important;
}

.ReactModalPortal {
    position: relative;
    z-index: 2000;
}

.ReactModal__Overlay {
    background: none !important;
    z-index: 999999 !important;
}

.ReactModal__Body--open {
    overflow-y: hidden;
}

.ReactModal__Content {
    position: relative;
    padding: 0px;
    background: none;
    border: none;
    z-index: 999999 !important;
}

.dialog-outer-wrapper-container {
    top: 120px;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 101;
    position: fixed;
    will-change: opacity;
    background-color: rgba(0, 0, 0, 0.54);
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    display: flex;
    justify-content: center;
    align-items: center;
}

.dialog-outer-wrapper-container.dialogFadeIn-appear {
    opacity: 0.01;
}

.dialog-outer-wrapper-container.dialogFadeIn-appear.dialogFadeIn-appear-active {
    opacity: 1;
    transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.dialog-wrapper-container {
    box-sizing: border-box;
    position: relative;
    z-index: 4;
    width: 765px;
    min-height: 200px;
    overflow: hidden;
    background: #fff;
    border-radius: 5px;
    margin: 0 20px;
    display: flex;
    flex-direction: column;
    max-height: 98vh;
}

.dialog-wrapper-container * {
    box-sizing: border-box;
}

.dialog-wrapper-container.dialog-wrapper-allow-overflow {
    overflow: visible;
}

.dialog-wrapper-container .embeded-wizard-dialog-content {
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    max-height: 98vh;
}

.dialog-wrapper-container .dialog-header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $regular;
    font-size: 24px;
    line-height: 20px;
    font-weight: 700;
    padding: 40px 40px 20px;
    max-height: 82px;
}

.dialog-wrapper-container .dialog-header-container .dialog-header-title {
    flex: 1;
    padding-right: 12px;
    text-align: left;
}

.dialog-wrapper-container .dialog-header-container .dialog-header-close {
    cursor: pointer;
}

.dialog-wrapper-container .dialog-header-container .dialog-header-close svg {
    width: 20px;
    height: 20px;
}

.dialog-wrapper-container .dialog-content-container {
    padding: 20px 40px 40px;
    overflow: auto;
    max-height: calc(100% - 164px);
    overflow-x: hidden;
}

.bdl-ItemSubDetails-modifiedBy {
    white-space: nowrap;
}

.be-modal-dialog-content {
    position: absolute;
    top: 50% !important;
    right: auto;
    bottom: auto;
    left: 50%;
    width: 400px;
    margin-right: -50%;
    padding: 25px;
    border-radius: 4px;
    transform: translate(-50%, -50%);
    border: rgb(202, 202, 202) solid 1px !important;
    box-shadow: 2px 2px 9px rgba(0, 0, 0, 0.1);
}

.be-modal-dialog-overlay {
    position: fixed !important;
}

.dialog-wrapper-container .dialog-content-container.dialog-content-allow-overflow {
    overflow-y: visible;
    overflow-x: visible;
}

.dialog-wrapper-container .dialog-content-container .dialog-message {
    font-size: 16px;
    text-align: left;
    // color: $subtitle;
    line-height: 22px;
}

.dialog-wrapper-container .dialog-content-container .dialog-message-form {
    padding-bottom: 24px;
}

.dialog-wrapper-container .dialog-action-container {
    display: flex;
    justify-content: center;
    align-items: center;
    // background: $grey-lighter;
    margin-top: 20px;
    padding: 20px 0 20px 12px;
    overflow: hidden;
    max-height: 82px;
}

.dialog-wrapper-container .dialog-action-container .button {
    cursor: pointer;
}

.dialog-wrapper-container .dialog-action-container .button.primary {
    padding: 4px 16px;
    min-width: 100px;
    height: 36px;
}

.dialog-wrapper-container.sm {
    width: calc(100% - 40px);
    max-width: 500px;
}

.dialog-wrapper-container.sm .dialog-header-container {
    padding: 40px 40px 20px;
}

.dialog-wrapper-container.sm .dialog-content-container {
    padding: 10px 40px;
}

.dialog-wrapper-container.md {
    width: calc(100% - 40px);
    max-width: 765px;
}

.dialog-wrapper-container.md .dialog-header-container {
    padding: 40px 40px 20px;
}

.dialog-wrapper-container.md .dialog-content-container {
    padding: 20px 40px 40px;
}

.dialog-wrapper-container.lg {
    width: calc(100% - 40px);
    max-width: 1010px;
}

.dialog-wrapper-container.lg .dialog-header-container {
    padding: 40px 40px 20px;
}

.dialog-wrapper-container.lg .dialog-content-container {
    padding: 20px 40px 40px;
}

.dialog-wrapper-container .form-section {
    padding: 0 0 24px;
}

.dialog-wrapper-container .form-section:last-child {
    padding-bottom: 0px;
}

.dialog-wrapper-container .form-group {
    justify-content: flex-start;
}

.dialog-wrapper-container .form-group .input {
    margin-left: 40px;
    width: 100%;
}

// @media (max-width: $screen-tablet) {
// 	.dialog-wrapper-container .form-group .input {
// 		margin-left: 0;
// 	}
// }

.dialog-wrapper-container .form-group > label {
    width: 315px;
    margin-right: 20px;
}

.dialog-wrapper-container .form-group:not(.input-remaining) .inputs,
.dialog-wrapper-container .form-group:not(.input-remaining) input {
    width: 350px;
}

.dialog-wrapper-container .form-group:not(.input-remaining).multi .inputs {
    width: 350px;
}

.dialog-wrapper-container .form-group:not(.input-remaining).multi input {
    width: 100%;
}

.dialog-wrapper-container .form-group:not(.input-remaining).multi .text-input {
    width: 140px;
}

.dialog-wrapper-container .form-group:not(.input-remaining).multi .select-field {
    width: 80px !important;
}

.dialog-wrapper-container-sidebar {
    box-sizing: border-box;
    position: relative;
    z-index: 4;
    width: 765px;
    min-height: 200px;
    overflow: hidden;
    background: #fff;
    border-radius: 5px;
    margin: 0 20px;
    display: flex;
    flex-direction: column;
    max-height: 75vh;
}

.dialog-wrapper-container-sidebar * {
    box-sizing: border-box;
}

.dialog-wrapper-container-sidebar.dialog-wrapper-allow-overflow {
    overflow: visible;
}

.dialog-wrapper-container-sidebar .embeded-wizard-dialog-content {
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    max-height: 75vh;
}

.dialog-wrapper-container-sidebar .dialog-header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $regular;
    font-size: 24px;
    line-height: 20px;
    font-weight: 700;
    padding: 40px 40px 20px;
    max-height: 82px;
}

.dialog-wrapper-container-sidebar .dialog-header-container .dialog-header-title {
    flex: 1;
    padding-right: 12px;
    text-align: left;
}

.dialog-wrapper-container-sidebar .dialog-header-container .dialog-header-close {
    cursor: pointer;
}

.dialog-wrapper-container-sidebar .dialog-header-container .dialog-header-close svg {
    width: 20px;
    height: 20px;
}

.dialog-wrapper-container-sidebar .dialog-content-container {
    padding: 20px 40px 40px;
    overflow: auto;
    max-height: calc(100% - 164px);
    overflow-x: hidden;
}

.dialog-wrapper-container-sidebar .dialog-content-container.dialog-content-allow-overflow {
    overflow-y: visible;
    overflow-x: visible;
}

.dialog-wrapper-container-sidebar .dialog-content-container .dialog-message {
    font-size: 16px;
    text-align: left;
    // color: $subtitle;
    line-height: 22px;
}

.dialog-wrapper-container-sidebar .dialog-content-container .dialog-message-form {
    padding-bottom: 24px;
}

.dialog-wrapper-container-sidebar .dialog-action-container {
    display: flex;
    justify-content: center;
    align-items: center;
    // background: $grey-lighter;
    margin-top: 20px;
    padding: 20px 0 20px 12px;
    overflow: hidden;
    max-height: 82px;
}

.dialog-wrapper-container-sidebar .dialog-action-container .button {
    cursor: pointer;
}

.dialog-wrapper-container-sidebar .dialog-action-container .button.primary {
    padding: 4px 16px;
    min-width: 100px;
    height: 36px;
}

.dialog-wrapper-container-sidebar.sm {
    width: calc(100% - 40px);
    max-width: 500px;
}

.dialog-wrapper-container-sidebar.sm .dialog-header-container {
    padding: 40px 40px 20px;
}

.dialog-wrapper-container-sidebar.sm .dialog-content-container {
    padding: 10px 28px;
}

.dialog-wrapper-container-sidebar.md {
    width: calc(100% - 40px);
    max-width: 765px;
}

.dialog-wrapper-container-sidebar.md .dialog-header-container {
    padding: 40px 40px 20px;
}

.dialog-wrapper-container-sidebar.md .dialog-content-container {
    padding: 20px 40px 40px;
}

.dialog-wrapper-container-sidebar.lg {
    width: calc(100% - 40px);
    max-width: 1010px;
}

.dialog-wrapper-container-sidebar.lg .dialog-header-container {
    padding: 40px 40px 20px;
}

.dialog-wrapper-container-sidebar.lg .dialog-content-container {
    padding: 20px 40px 40px;
}

.dialog-wrapper-container-sidebar .form-section {
    padding: 0 0 24px;
}

.dialog-wrapper-container-sidebar .form-section:last-child {
    padding-bottom: 0px;
}

.dialog-wrapper-container-sidebar .form-group {
    justify-content: flex-start;
}

.dialog-wrapper-container-sidebar .form-group > label {
    width: 315px;
    margin-right: 20px;
}

.dialog-wrapper-container-sidebar .form-group:not(.input-remaining) .inputs,
.dialog-wrapper-container-sidebar .form-group:not(.input-remaining) input {
    width: 100%;
}

.dialog-wrapper-container-sidebar .form-group:not(.input-remaining).multi .inputs {
    width: 100%;
}

.dialog-wrapper-container-sidebar .form-group:not(.input-remaining).multi input {
    width: 100%;
}

.dialog-wrapper-container-sidebar .form-group:not(.input-remaining).multi .text-input {
    width: 140px;
}

.dialog-wrapper-container-sidebar .form-group:not(.input-remaining).multi .select-field {
    width: 80px !important;
}

.dialog-wrapper-container-sidebar .select-section.form-section {
    padding-bottom: 10px;
}

.dialog-wrapper-container-sidebar .radio-button input {
    cursor: pointer;
}

.dialog-wrapper-container-sidebar .radio-button span {
    z-index: -1;
}

.dialog-wrapper-container-sidebar h3 {
    margin: 0px !important;
    padding-top: 16px;
    padding-bottom: 32px;
}

.dialog-wrapper-container-sidebar p {
    margin: 0 !important;
}

.dialog-wrapper-container-sidebar.sm .form-group {
    flex-direction: column;
    align-items: flex-start;
}

.dialog-wrapper-container-sidebar.sm .form-group > label {
    text-align: left;
    padding-bottom: 8px;
}

.dialog-wrapper-container-sidebar.sm .select-section.form-section {
    height: 70px;
}

.dialog-wrapper-container-sidebar .hint {
    margin: 0;
    margin-right: 16px;
    right: 100%;
    left: auto;
    top: 0;
}

.dialog-wrapper-container-sidebar .hint h2 {
    display: block;
}

.dialog-wrapper-container-sidebar .hint:before {
    margin-right: -1px;
    // border-left-color: $hint-border;
    right: -20px;
    border-right-color: transparent;
    left: auto;
}

.dialog-wrapper-container-sidebar .hint:after {
    // border-left-color: $hint-background;
    right: -20px;
    border-right-color: transparent;
    left: auto;
}

.dialog-wrapper-container-sidebar.sm .hint,
.dialog-wrapper-container-sidebar.embedded .hint {
    margin-top: 12px;
    left: 0;
    top: 100%;
    width: 100%;
}

.dialog-wrapper-container-sidebar.sm .hint h2,
.dialog-wrapper-container-sidebar.embedded .hint h2 {
    display: none;
}

.dialog-wrapper-container-sidebar.sm .hint:before,
.dialog-wrapper-container-sidebar.embedded .hint:before,
.dialog-wrapper-container-sidebar.sm .hint:after,
.dialog-wrapper-container-sidebar.embedded .hint:after {
    border-color: transparent;
    border-width: 10px;
    border-top-width: 0;
    top: -10px;
    left: 10px;
}

.dialog-wrapper-container-sidebar.sm .hint:before,
.dialog-wrapper-container-sidebar.embedded .hint:before {
    margin: 0;
    margin-top: -1px;
    // border-bottom-color: $hint-border;
}

.dialog-wrapper-container-sidebar.sm .hint:after,
.dialog-wrapper-container-sidebar.embedded .hint:after {
    // border-bottom-color: $hint-background;
}

.dialog-wrapper-container-sidebar.lg .hint {
    width: 229px;
    left: 100%;
    margin-left: 16px;
    top: 0;
}

.dialog-wrapper-container-sidebar.lg .hint h2 {
    display: none;
}

.dialog-wrapper-container-sidebar.lg .hint:before {
    border-left-color: transparent;
    // border-right-color: $hint-border;
    left: -20px;
}

.dialog-wrapper-container-sidebar.lg .hint:after {
    border-left-color: transparent;
    // border-right-color: $hint-background;
    left: -20px;
}

@media (max-width: 1049px) {
    .dialog-wrapper-container.lg {
        width: calc(100% - 40px);
        max-width: 765px;
    }

    .dialog-wrapper-container.lg .dialog-header-container {
        padding: 40px 40px 20px;
    }

    .dialog-wrapper-container.lg .dialog-content-container {
        padding: 20px 40px 40px;
    }

    .dialog-wrapper-container.lg .hint {
        margin: 0;
        margin-right: 16px;
        right: 100%;
        left: auto;
        top: 0;
    }

    .dialog-wrapper-container.lg .hint h2 {
        display: block;
    }

    .dialog-wrapper-container.lg .hint:before {
        margin-right: -1px;
        // border-left-color: $hint-border;
        right: -20px;
        border-right-color: transparent;
        left: auto;
    }

    .dialog-wrapper-container.lg .hint:after {
        // border-left-color: $hint-background;
        right: -20px;
        border-right-color: transparent;
        left: auto;
    }
}

@media (max-width: 804px) {
    .dialog-wrapper-container.lg,
    .dialog-wrapper-container.md,
    .dialog-wrapper-container.sm {
        width: calc(100% - 40px);
        max-width: 500px;
    }

    .dialog-wrapper-container.lg .dialog-header-container,
    .dialog-wrapper-container.md .dialog-header-container,
    .dialog-wrapper-container.sm .dialog-header-container {
        padding: 40px 32px 20px;
    }

    .dialog-wrapper-container.lg .dialog-content-container,
    .dialog-wrapper-container.md .dialog-content-container,
    .dialog-wrapper-container.sm .dialog-content-container {
        padding: 20px 32px 32px;
    }

    .dialog-wrapper-container.lg .form-group,
    .dialog-wrapper-container.md .form-group,
    .dialog-wrapper-container.sm .form-group {
        flex-direction: column;
        align-items: flex-start;
    }

    .dialog-wrapper-container.lg .form-group > label,
    .dialog-wrapper-container.md .form-group > label,
    .dialog-wrapper-container.sm .form-group > label {
        text-align: left;
        padding-bottom: 8px;
        margin-bottom: 0px;
        margin-right: 0px;
        width: 100%;
        max-width: 315px;
    }

    .dialog-wrapper-container.lg .form-group .inputs,
    .dialog-wrapper-container.md .form-group .inputs,
    .dialog-wrapper-container.sm .form-group .inputs {
        max-width: 350px;
        width: 100%;
    }

    .dialog-wrapper-container.lg .hint,
    .dialog-wrapper-container.md .hint,
    .dialog-wrapper-container.sm .hint {
        margin-top: 12px;
        left: 0;
        top: 100%;
        width: 100%;
    }

    .dialog-wrapper-container.lg .hint h2,
    .dialog-wrapper-container.md .hint h2,
    .dialog-wrapper-container.sm .hint h2 {
        display: none;
    }

    .dialog-wrapper-container.lg .hint:before,
    .dialog-wrapper-container.md .hint:before,
    .dialog-wrapper-container.sm .hint:before,
    .dialog-wrapper-container.lg .hint:after,
    .dialog-wrapper-container.md .hint:after,
    .dialog-wrapper-container.sm .hint:after {
        border-color: transparent;
        border-width: 10px;
        border-top-width: 0;
        top: -10px;
        left: 10px;
    }

    .dialog-wrapper-container.lg .hint:before,
    .dialog-wrapper-container.md .hint:before,
    .dialog-wrapper-container.sm .hint:before {
        margin: 0;
        margin-top: -1px;
        // border-bottom-color: $hint-border;
    }

    .dialog-wrapper-container.lg .hint:after,
    .dialog-wrapper-container.md .hint:after,
    .dialog-wrapper-container.sm .hint:after {
        // border-bottom-color: $hint-background;
    }
}

.input-selector {
    padding: 5px;
    position: relative;
    right: 5px;
    min-height: 25px;
}

.input-selector svg {
    display: none;
}

.input-selector:hover,
.input-selector:focus {
    background-color: none;
}

.input-selector:hover svg,
.input-selector:focus svg {
    display: inline-flex;
    color: #1180ff;
    width: 16px;
    height: 16px;
    float: right;
}

.document-sidebar .task-status-sidebar {
    display: flex;
    font-size: 15px;
    height: 28px;
    justify-content: center;
    position: relative;
    right: 60px;
    color: #529fdf;
}

.document-sidebar .task-status-sidebar.task-completed {
    color: #3a6;
}

// @media (max-width: $screen-mobile) {
// 	.document-sidebar .task-status-sidebar {
// 		position: inherit;
// 	}
// }

.document-sidebar .task-sidebar-status {
    position: relative;
    padding-left: 8px;
    top: 3px;
}

.document-sidebar .task-sidebar-date {
    cursor: pointer;
}

.document-sidebar .task-quote-button {
    height: 10px;
}

.task-sidebar-content,
.folder-sidebar-content {
    padding: 0;
}

.task-sidebar-content .task-name,
.folder-sidebar-content .task-name {
    // color: $grey-dark;
    font-size: 20px;
    font-weight: 700;
}

.task-sidebar-content div.form-group,
.folder-sidebar-content div.form-group {
    padding-top: 0;
}

.task-sidebar-content div.form-group div.inputs,
.folder-sidebar-content div.form-group div.inputs {
    width: 100% !important;
    max-width: 100% !important;
}

.task-sidebar-content .multi-select-search-placeholder,
.folder-sidebar-content .multi-select-search-placeholder {
    align-items: center;
    // color: $grey;
    display: flex;
    font-size: 14px;
}

.task-sidebar-content .multi-select-search-placeholder svg,
.folder-sidebar-content .multi-select-search-placeholder svg {
    margin-right: 10px;
    margin-left: 5px;
}

.task-sidebar-content .multi-select-search-placeholder svg path,
.folder-sidebar-content .multi-select-search-placeholder svg path {
    // fill: $grey;
}

.task-sidebar-content h4,
.folder-sidebar-content h4 {
    margin-bottom: 12px;
}

.task-sidebar-content .dialog-wrapper-container.sm,
.folder-sidebar-content .dialog-wrapper-container.sm {
    margin: 0;
    width: 100%;
    max-width: 100%;
    z-index: 0;
}

.task-sidebar-content .due-date-and-reminders,
.folder-sidebar-content .due-date-and-reminders {
    display: flex;
    justify-content: space-between;
}

.task-sidebar-content .due-date-and-reminders > div,
.folder-sidebar-content .due-date-and-reminders > div {
    margin: 3px;
}

.task-sidebar-content .due-date-and-reminders .with-tooltip-i,
.folder-sidebar-content .due-date-and-reminders .with-tooltip-i {
    margin-top: 1.33em;
}

.task-sidebar-content .due-date-and-reminders .with-tooltip-i h4,
.folder-sidebar-content .due-date-and-reminders .with-tooltip-i h4 {
    margin-top: 0px;
}

.task-sidebar-content .due-date-and-reminders input,
.folder-sidebar-content .due-date-and-reminders input,
.task-sidebar-content .due-date-and-reminders .select-field,
.folder-sidebar-content .due-date-and-reminders .select-field {
    // color: $grey-dark;
    font-size: 14px;
    width: 130px;
}

.react-datepicker-container {
    width: 100%;
}

.react-datepicker-wrapper {
    width: 100%;
}

.react-datepicker-wrapper input {
    outline: none;
    border-radius: 2px;
    padding-left: 10px;
    min-height: 39px;
    border: 1px solid rgba(45, 51, 67, 0.2);
}

.react-datepicker-wrapper input.width-250 {
    width: 250px;
}

.react-datepicker-wrapper .react-datepicker__close-icon::after {
    background: #81858e;
    color: #fff;
    height: 12px;
    width: 12px;
    font-weight: bold;
}

.react-datepicker-container .react-datepicker-wrapper {
    width: 95%;
}

.error-datepicker {
    border-color: #e27886 !important;
    color: #e27886;
}

.datepicker-past--outer {
    width: 100%;
}

.datepicker-past--input {
    width: 33.33%;
    display: inline-block;
    padding: 0 15px;
    box-sizing: border-box;
    vertical-align: bottom;
    webkittransform: 'none';
}

.datepicker-past--input div {
    max-width: 100%;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
}
