.dropzone {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 32px;
    padding-bottom: 48px;
    border:1px dashed rgba(0, 0, 0, 0.38) !important;
    border-radius: 4px;
    background-color: white;
    color: gray;
    outline: none;
    transition: border 0.24s ease-in-out;
    min-height: 220px;
    justify-content: center;
}

.dropzone:hover,
.dropzone > input:focus {
    border-color: #2b6879;
}

.dropzone > p {
    margin: 0;
    padding: 0;
}

.dropzone:hover > p,
.dropzone > input:focus ~ p {
    color: #2b6879;
}

.uploadIcon {
    width: 86px;
    height: 86px;
    margin-bottom: 8px;
}
