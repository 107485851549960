//  Core

.nav-icon-wrapper {
  width: 32px;
  text-align: center;
}

.MuiListItem-root.MuiListItem-button {
  .MuiTouchRipple-root {
    opacity: .5;
  }
}

body {

  .MuiListItem-root {
    width: auto;
  }

  .MuiList-root {
    &.nav-transparent {
      .MuiListItem-button {
        .MuiTouchRipple-root {
          opacity: 0 !important;
        }

        &.text-white-50 {
          .MuiTouchRipple-root {
            opacity: .2 !important;
          }

          &.Mui-selected,
          &:hover {
            color: $white !important;
            background: rgba($white, .05);
          }
        }
      }
    }
  }

  .list-group-flush {
    padding-top: 0;
    padding-bottom: 0;

    .MuiListItem-root {
      padding: $list-group-item-padding-y $list-group-item-padding-x;
      color: $list-group-color;
      background-color: $list-group-bg;
      border: $list-group-border-width solid $list-group-border-color;
      @include border-radius(0);
      margin: 0;
      border-width: 0 0 1px;

      &.MuiListItem-button {
        @include border-radius(0);
        border-width: 0 0 1px;

        .MuiTouchRipple-root {
          opacity: .2;
        }
      }

      &.list-group-item-action {
        transition: $transition-base;
      }

      &.MuiListItem-button,
      &.list-group-item-action {
        &:hover {
          background: $list-group-hover-bg;
          z-index: 1;
        }

        &:active {
          color: $list-group-action-active-color;
          background-color: $list-group-action-active-bg;
        }
      }

      &:first-child {
        border-top: 0 !important;
      }

      &:last-child {
        border-bottom: 0 !important;
      }
    }
  }

  .list-group-bordered {
    .MuiListItem-root {
      border-bottom: $list-group-border-color solid 1px;
      border-left: $list-group-border-color solid 1px;
      border-right: $list-group-border-color solid 1px;
      margin: 0;

      &:first-child {
        border-top: $list-group-border-color solid 1px;
        @include border-top-radius($list-group-border-radius);
      }

      &:last-child {
        @include border-bottom-radius($list-group-border-radius);
      }
    }
  }

  .MuiCard-root {
    & > .MuiList-root:last-child {
      .MuiListItem-root:last-child {
        @include border-bottom-radius($card-border-radius);
      }
    }
  }
}
