.MuiDropzonePreviewList-root {
    margin: 0;
    margin-top: 30px;
}

.MuiDropzonePreviewList-root > div {
    border: 1px solid #ccc;
}

/* Drag area */

.drag-zone {
    font-family: 'Inter';
}

.drag-zone .clean {
    margin: 15px 0px;
}

.file-input {
    /* Force replace survey style*/
    opacity: 0 !important;
    position: absolute !important;
    z-index: 1;
}

.label-file-input {
    border: 1px #013c4d;
    border-style: dashed;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: move;
}

.file-input,
.label-file-input {
    width: 100%;
    min-height: 150px;
}

.label-file-input.ondrag {
    background: #013c4d;
    transition: ease-in-out 0.5s all;
}

.label-file-input.ondrag p {
    color: #fff;
    transition: ease-in-out 0.5s all;
}

.file-input:disabled ~ label {
    background: #ccc;
}

.label-file-input p {
    margin: 0;
    max-width: 170px;
    font-weight: 500;
    color: #555;
    text-align: center;
}

.container-preview {
    display: grid;
    grid-gap: 15px;
    grid-template-columns: auto auto;
}

.file-preview {
    padding: 15px;
    position: relative;
    width: 100%;
    height: 100px;
    margin: 0;
    display: inline-flex;
    flex-direction: column;
    justify-content: space-between;
    vertical-align: top;
    border: 1px solid lightgray;
    box-sizing: border-box;
}

.file-preview-name a {
    color: #009688;
    text-decoration: none;
    background-color: transparent;
    font-size: 14px;
    cursor: pointer;
}

.file-preview-remove {
    font-size: 12px;
    cursor: pointer;
}

.file-preview-remove:hover {
    color: #009688;
}
