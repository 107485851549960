//  Core

.avatar-icon-wrapper {
  display: inline-block;
  margin-right: ($spacer / 4);
  position: relative;
  font-size: 1.2rem;
  transition: $transition-base;

  &:last-child {
    margin-right: 0;
  }

  .badge {
    position: absolute;
    right: 0;
    bottom: 0;
  }
}

.avatar-icon {
  display: block;
  width: 44px;
  height: 44px;
  line-height: 44px;
  transition: $transition-base;
  opacity: 1;

  @include border-radius(50px);

  &.rounded {
    @include border-radius(($border-radius) !important);
  }
}

.avatar-icon-xl {
  font-size: 3.2rem;
  display: flex;
  text-align-last: center;
  .avatar-icon {
    width: 64px;
    height: 64px;
    line-height: 64px;
  }
}

.avatar-icon-lg {
  font-size: 3.2rem;
  display: flex;
  text-align-last: center;
  box-shadow: none;
  overflow: hidden;
  margin-right: 0px;
  .avatar-icon {
    box-shadow: none;
    overflow: hidden;
    width: 75px;
    height: 75px;
    line-height: 75px;
    &.rounded {
      @include border-radius(none !important);
      
    }
  }
}

.avatar-icon-sm {
  font-size: .9rem;
  font-weight: bold;
  display: flex;
  box-shadow: none !important;
  .avatar-icon {
    width: 26px;
    height: 26px;
    line-height: 26px;
    text-align-last: center;
    box-shadow: none !important;
    background-color: #abb3bd;
    color:$white;
    &.rounded {
      @include border-radius(0.3rem !important);
    }
  }
}



.avatar-icon-sm-multi {
  font-size: .9rem;
  font-weight: bold;
  display: flex;

  .avatar-icon {
    width: 26px;
    height: 26px;
    line-height: 26px;
    text-align-last: center;
    background-color: #abb3bd;
    color:$white;
    &.rounded {
      @include border-radius(0.3rem !important);
    }
  }
}

.avatar-icon-md {
  font-size: 1.2rem;
  font-weight: bold;
  display: flex;
  box-shadow: none !important;
  .avatar-icon {
    width: 40px;
    height: 40px;
    box-shadow: none;
    text-align-last: center;
    line-height: 40px;
    box-shadow: none !important;
    background-color: #abb3bd;
    color:$white;
    &.rounded {
      @include border-radius(20% !important);
    }
  }
}

.avatar-icon-xs {
  font-size: 0.7rem;
  font-weight: bold;
  display: flex;
  text-align-last: center;

  .avatar-icon {
    width: 26px;
    height: 26px;
    line-height: 26px;
    transition: transform 0.2s;
  }
}

.avatar-icon {
  box-shadow: 0 0 0 3px white !important;
  background-color: #abb3bd;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    display: block;
  }
}

.avatar-wrapper-overlap {
  display: flex;

  .avatar-icon-wrapper {
    z-index: 5;
    margin-left: -18px;

    &:hover {
      z-index: 7;
    }

    &.avatar-icon-xl {
      margin-left: -30px;
    }

    &.avatar-icon-lg {
      margin-left: -24px;
    }

    &.avatar-icon-sm {
      margin-left: -14px;
    }

    &.avatar-icon-xs {
      margin-left: -10px;
    }

    &:first-child {
      margin-left: 0 !important;
    }
  }
}

.avatar-wrapper-overlap-hover {
  .avatar-icon-wrapper {
    &:hover {
      z-index: 5;
    }
  }

  &:hover {
    .avatar-icon-wrapper {
      margin-left: -18px;

      &:first-child {
        transform: translateX(-22px);
      }

      &:last-child {
        transform: translateX(22px);
      }
    }

    .avatar-icon-xl {
      margin-left: -30px;

      &:first-child {
        transform: translateX(-34px);
      }

      &:last-child {
        transform: translateX(34px);
      }
    }

    .avatar-icon-lg {
      margin-left: -24px;

      &:first-child {
        transform: translateX(-28px);
      }

      &:last-child {
        transform: translateX(28px);
      }
    }

    .avatar-icon-sm {
      margin-left: -14px;

      &:first-child {
        transform: translateX(-18px);
      }

      &:last-child {
        transform: translateX(18px);
      }
    }

    .avatar-icon-xs {
      margin-left: -10px;

      &:first-child {
        transform: translateX(-14px);
      }

      &:last-child {
        transform: translateX(14px);
      }
    }
  }
}

// Initials avatars

.avatar-initials {
  text-align: center;
  text-transform: uppercase;
}
