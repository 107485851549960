//  Core

body {
  .d-44 {
    height: 44px;
    line-height: 44px;
    width: 44px;
  }

  .height-280 {
    height: 260px;
    width: 400px;
  }

  .app-header-dots {
    overflow: hidden;
    min-height: 493px;
  }
}
